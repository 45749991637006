import React from 'react';
import '../styles/Header.css';
import image1 from '../assets/images/logo.png';

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={image1} alt="Provider Portal Logo" className="logo" />
        <span className="logo-text">COREMED 360</span>
      </div>
    </header>
  );
};

export default Header;
