// Function to extract the CSRF token from cookies
export const getCSRFToken = () => {
  const name = 'csrftoken';
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
};

// Fetch the CSRF token by making a request to the Django backend
export const fetchCSRFToken = async () => {
  const response = await fetch('https://price.coremedhealth.com/api/get-csrf-token/', {  // Update to your EC2 instance or domain
    credentials: 'include',  // Ensures cookies (including CSRF) are included in the request
  });

  if (response.ok) {
    return getCSRFToken();  // Returns the CSRF token from cookies
  }

  throw new Error('Failed to fetch CSRF token');
};
