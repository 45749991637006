import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { fetchCSRFToken } from './utils/csrf';
import Layout from './components/Layout';
import Loading from './components/Loading';

const PremiumCalculator = lazy(() => import('./pages/PremiumCalculator'));

function App() {
  useEffect(() => {
    const initializeCSRF = async () => {
      try {
        await fetchCSRFToken();
      } catch (error) {
        console.error('CSRF token fetch failed:', error);
      }
    };
    initializeCSRF();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="/" element={<Layout><PremiumCalculator /></Layout>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
